import React from 'react';
import MultiCategoryDisplay from '../layout/MainBestilLayout/MultiCategoryDisplay';
import { Paragraph, LinkStyled } from '../../../components/typography';
import { Row, Col } from '../../../components/grid';
import { SPECIAL_NOTICE_ENABLED } from '../../../settings';
import ButtonToolBestil from '../../../components/ButtonToolBestil';

export default () => (
  <React.Fragment>
    <Row>
      <Col xs={12} paddingTop="0px" paddingBottom="0px">
        <ButtonToolBestil />
      </Col>
    </Row>
    {SPECIAL_NOTICE_ENABLED && (
      <Row>
        <Col xs={12} paddingBottom="5px">
          <Paragraph bold fontSize="18px" red marginBottom="0px">
            Bryggerierne varsler prisstigninger fra 1. juli og 1. august 2022. Priserne på leveringsdagen vil være gældende. Forventet stigning 5-9%.
          </Paragraph>
        </Col>
      </Row>
    )}
    <MultiCategoryDisplay page="FadoelOgAnlaeg" subPage="Fadoel" />
    <Paragraph paddingBottom="0px" paddingTop="10px">
      Priserne er <LinkStyled to="/bestil/returpant">uden pant</LinkStyled>
    </Paragraph>
  </React.Fragment>
);
